import Vue from 'vue';
import jquery from 'jquery';
const axios = require('axios');

export default {
  name: 'products',
  mounted(){
    $('.carousel').carousel();

  },
  data() {
    return {
      title: 'Vue.js products',
    }
  },
  methods: {
    forceFileDownload(response, filename){
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename) //or any other extension
      document.body.appendChild(link)
      link.click()
    },

    downloadWithAxios(mylink, filename){
      console.log('selected link ' + mylink)
      axios({
        method: 'get',
        url: mylink,
        responseType: 'blob'
      })
        .then(response => {

          this.forceFileDownload(response, filename)

        })
        .catch(() => console.log('error occured'))
    }
  },
  computed: {

  }
}
