import Vue from 'vue';
import jquery from 'jquery';

export default {
  name: 'home',

  mounted(){
    $('.carousel').carousel();

  }
}


