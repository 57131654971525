import Vue from 'vue';
import Router from 'vue-router';
import Home from '../components/home/home.vue';
import Products from '../components/products/products.vue';
import Contact from '../components/contact/contact.vue';
import About from '../components/about/about.vue';
Vue.use(Router);
const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {title: 'Kahroba'}
    },
    {
      path: '/products',
      name: 'products',
      component: Products,
      meta: {title: 'Products'},
      props: true
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
      meta: {title: 'Contact'}
    },
    {
      path: '/about',
      name: 'about',
      component: About,
      meta: {title: 'About'}
    }
  ]
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next()
})

export default router
