import Vue from 'vue';


export default {
  name: 'c_footer',
  data() {
    return {
      title: 'Vue.js footer'
    }
  },

  methods: {}
}
