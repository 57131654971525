import Vue from 'vue';
const axios = require('axios');

export default {
  name: 'contact',
  components:{
  },
  data() {
    return {
      title: 'Vue.js contact',
      contact_response:null,
      contact_result:null,
      form: {
        email: '',
        name: '',
        phone: '',
        message: ''
      },
      show: true
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      var vm=this
      axios.post('https://' + vm.address + '/contactform', vm.form)
        .then(function (response) {
          console.log('Contact form sent.')
          vm.contact_response = response.data
          vm.contact_result = {
            successful: true,
            message: 'Message Sent Successfully.'
          }

        })
        .catch(function (error) {
          console.log(error)
          vm.contact_result = {
            successful: false,
            message: error.response.data
          }
        });
      //alert(JSON.stringify(this.form))
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.name = ''
      this.form.phone = ''
      this.form.message = ''
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
