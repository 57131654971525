import nav_bar from './components/nav_bar/nav_bar.vue';
import c_footer from './components/footer/footer.vue'


export default {
  name: 'App',
  title: 'Kahroba',
  components: {
    nav_bar,
    c_footer
  },
  data() {
    return {
      state: 'disconnected',
      counter: 0
    }
  },
  methods: {
    update_state: function (state) {
      this.state=state;
    },
    increas(){
      this.counter = this.counter

      this.$ga.event({
        eventCategory:'counter',
        eventAction: 'increas',
        eventValue: this.counter
      })
    }
  }

};

