exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../assets/10556.jpg"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../assets/milad.png"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../assets/esmail.jpg"));

// Module
exports.push([module.id, "\n.profile {\n  position: relative;\n  width: 150px;\n  height: 150px;\n  /*left: 50px;*/\n  border-radius: 50%;\n  text-align: center;\n  box-shadow: 2px 2px 3px #999;\n  z-index: 20;\n  background-image: url(" + ___CSS_LOADER_URL___0___ + ");\n  background-repeat: no-repeat;\n  background-size: 100%;\n  background-position: center;\n}\n.profile_1 {\n  position: relative;\n  width: 150px;\n  height: 150px;\n  border-radius: 50%;\n  text-align: center;\n  box-shadow: 2px 2px 3px #999;\n  z-index: 20;\n  background-image: url(" + ___CSS_LOADER_URL___1___ + ");\n  background-repeat: no-repeat;\n  background-size: 100%;\n  background-position: center;\n}\n.profile_2 {\n  position: relative;\n  width: 150px;\n  height: 150px;\n  border-radius: 50%;\n  text-align: center;\n  box-shadow: 2px 2px 3px #999;\n  z-index: 20;\n  background-image: url(" + ___CSS_LOADER_URL___2___ + ");\n  background-repeat: no-repeat;\n  background-size: 100%;\n  background-position: center;\n}\n.bg-light {\n  justify-content: center;\n}\n", ""]);

