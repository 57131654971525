import Vue from 'vue';

export default {
  name: 'nav_bar',
  props: ['state'],
  data() {
    return {
      title: 'Vue.js nav_bar'
    }
  },
  methods: {},
  computed: {
    stateClass: function () {
      return {
        'state-disconnected': this.state == 'disconnected',
        'state-idle': this.state == 'connected' || this.state == 'idle',
        'state-busy': this.state == 'busy'
      }
    }
  }
}
