import Vue from 'vue';

export default {
  name: 'about',
  data() {
    return {
      title: 'Vue.js about'
    }
  },
  methods: {},
  computed: {

  }
}
