import Vue from 'vue'

import Router from 'vue-router'
import Meta from 'vue-meta'
import uploader from 'vue-simple-uploader'
import App from './App.vue'
import VueVideoPlayer from 'vue-video-player'
import '!vue-style-loader!css-loader!video.js/dist/video-js.min.css';


import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery'
import 'bootstrap/dist/js/bootstrap.min'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import BootstrapVue from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown} from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faTwitter, faInstagram} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import router from './router'

import VueAnalytics from 'vue-analytics'

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
const  isProd = process.env.NODE_ENV ==' production'

library.add(faAngleDown,faFacebookF, faTwitter, faInstagram)

Vue.use(BootstrapVue)

Vue.use(uploader)
Vue.use(Router)
Vue.use(Meta)

Vue.use(VueVideoPlayer)

Vue.use(VueAnalytics, {
  id: 'UA-140310239-1',
  router,
  debug: {
    enabled : false,
    sendHitTask: true
  },
  autoTracking: {
    exception : true,

  }

})


new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>',
  //render: h => h(App)
})
